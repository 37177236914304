import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutPrimary from "../templates/LayoutPrimary";
import "../styles/components/nav.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import { imgBox } from "../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  contactBlock,
} from "../styles/components/blocks.module.scss";
import {
  formContainer,
  formHeadline,
  formWrapper,
  formItem,
} from "../styles/components/forms.module.scss";
import { btn } from "../styles/components/buttons.module.scss";
import { h1, sub } from "../styles/components/titles.module.scss";
import { hideBlockSM } from "../styles/components/helpers.module.scss";

const ContactPage = () => {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://getform.io/f/3f2aab29-cf89-48a8-ac01-bb150c0dc6dc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        console.log("Form submitted successfully");
        
        // If form submission is successful, redirect to the thank you page
        navigate("/thankYou");
      } else {
        // Handle error if form submission fails
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <LayoutPrimary>
      <Helmet>
        <title>Contact | Tampa Bay Boating Adventures</title>
        <meta
          name="description"
          content="Contact Tampa’s TOP private party boat experience to book today! Your ideal charter awaits. Reach out and let’s make this party happen!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Happy guy with girls on a party boat near Tampa, FL."
                  src="../images/banners/happy-guy-with-girls-on-party-boat.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  Contact Tampa Bay Boating Adventures
                </h1>
                <span className={bannerBlockTitleSm}>Get in Touch</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className={contactBlock}>
          <div className="row middle">
            <div className="col-md-7 col-xxs-12">
              <div className="this-pad-all-4-lg this-pad-all-2-sm text-left-sm text-center">
                <h2 className={h1}>
                  Send TBBA a <br className={hideBlockSM} />
                  Quick Message{" "}
                  <span className={sub}>to Get More Information</span>
                </h2>
                <p>
                  We want you to have the best party boat experience you can,
                  and we value your feedback! Shoot us a quick message and let
                  us know how we can help you enjoy your party boat experience
                  to its fullest!
                </p>

                <div className={` ${btn}`}>
                  <Link to="tel:8137428178" className="link call-style">
                    Call (813) 742-8178
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xxs-12 first">
              <div className={formContainer}>
                <div className={formHeadline}>
                  <div className={h1}>
                    <span className={sub}>Ahoy! Send Us a</span> Quick Message
                  </div>
                </div>
                <form onSubmit={handleSubmit} className="tbbaForm">
                  <div className={formWrapper}>
                    <div className={formItem}>
                      <label htmlFor="tbbaFormName">Your Name</label>
                      <input
                        id="tbbaFormName"
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={formItem}>
                      <label htmlFor="tbbaFormEmail">Email Address</label>
                      <input
                        id="tbbaFormEmail"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={formItem}>
                      <label htmlFor="tbbaFormMessage">How Can We Help?</label>
                      <textarea
                        id="tbbaFormMessage"
                        type="text"
                        name="message"
                        placeholder="How Can We Help?"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={formItem}>
                      <button type="submit" className={btn}>
                        Send Your Message!
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutPrimary>
  );
};

export default ContactPage;
